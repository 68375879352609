export default {
  color: 'var(--white)',
  gridColumnMobile: 'repeat(2, 1fr)',
  creditorsColumnsGrid: 'repeat(6, 1fr)',
  creditorsColumns: 6,
  borderRadius: '28px',
  borderWidth: '2px',
  borderColor: '#6bd100',
  margin: 'auto',
  imageWidth: '100%',
  buttonBackgroundColor: '#6bd100',
  borderStyle: 'solid',
  textDecExpandBut: 'none',
  expButPadding: '0.8em',
  displayExpButton: 'block',
  iconColor: 'var(--white)',
  expandButtonFont: '1rem',
  lineBreak: 'anywhere',
  cardPadding: '2.5rem',
};
